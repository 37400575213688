// Here you can add other styles
.avatar-border{
    border:0.05px solid #6c6f74;
}
.react-autosuggest__suggestions-list{
    list-style-type:none;
    padding-left: 0;
}

.react-autosuggest__suggestion {    
    list-style:none;
}